.About {
  width: 90vh;
  max-width: 95%;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid gray;
  margin-left: auto;
  margin-right: auto;
  padding: 1%;
}

.About #meat {
  padding: 20px;
}

.About a:hover {
  background: blue;
  color: white;
}

.About li {
  margin: 2px 0;
}
.About ul {
  list-style-type: circle;
}
