.outer {

    width: 110vh;
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
    #border: 1px solid gray;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
}

.content-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 40vh 0;
}

.content-holder div {
    #border: 1px solid;
}

#descriptions {
    width: 320%;
    font-size: 16px;
}

.descr {
    border: 1px solid;
    padding: 10px;
    position: sticky;
    top: 30vh;
}


#stories {
    width: 680%;
}

.Article {
    display: flex;
    flex-direction: row;
    margin: 50vh 5%;
}

.img {
    height: 8vw;
}

.Article .img {
    #width: 140px;
    #height: 90px;
    width: 300px;
    height: 170px;
    #margin-right: 12px;
    #margin-left: 12px;
}

.Article .headline {
    margin-left: 70px;
    margin-top: -50px;
    background: white;
    #border: 1px solid gray;
    padding: 2px;
}

.Article h3 {
    margin: 0;
}

.Article h5 {
    margin-top: 5px;
    color: gray;
    border-top: 1px solid lightgray;
}

  
#portfolio {
    margin-top: 150px;
    #padding: 20px;
}

#other_ex {
    width: 90vh;
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid gray;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
  }
  
  #other_ex_2 {
    padding: 20px;
  }
  