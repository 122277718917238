@import url(https://fonts.googleapis.com/css?family=Buenard:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection {
  color: white;
  background: pink;
}


* {
  font-family: 'Buenard', serif;
  #background-color: #fff0f2;
}

html {
  scroll-behavior: smooth;
}


.App {
  #border: 1px solid lightgray;
  margin-left: auto;
  margin-right: auto;
}


button {
  margin: 0;
  background: none;
  border: none;
}

#header {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

a:visited {
  color: blue;
}

#go_to_top {
  position: fixed;
  background: lightblue;
  right: 20px;
  bottom: 20px;
  padding: 5px;
  display: inline-block;
  border: 1px solid;
}

.About {
  width: 90vh;
  max-width: 95%;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid gray;
  margin-left: auto;
  margin-right: auto;
  padding: 1%;
}

.About #meat {
  padding: 20px;
}

.About a:hover {
  background: blue;
  color: white;
}

.About li {
  margin: 2px 0;
}
.About ul {
  list-style-type: circle;
}

.outer {

    width: 110vh;
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
    #border: 1px solid gray;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
}

.content-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 40vh 0;
}

.content-holder div {
    #border: 1px solid;
}

#descriptions {
    width: 320%;
    font-size: 16px;
}

.descr {
    border: 1px solid;
    padding: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 30vh;
}


#stories {
    width: 680%;
}

.Article {
    display: flex;
    flex-direction: row;
    margin: 50vh 5%;
}

.img {
    height: 8vw;
}

.Article .img {
    #width: 140px;
    #height: 90px;
    width: 300px;
    height: 170px;
    #margin-right: 12px;
    #margin-left: 12px;
}

.Article .headline {
    margin-left: 70px;
    margin-top: -50px;
    background: white;
    #border: 1px solid gray;
    padding: 2px;
}

.Article h3 {
    margin: 0;
}

.Article h5 {
    margin-top: 5px;
    color: gray;
    border-top: 1px solid lightgray;
}

  
#portfolio {
    margin-top: 150px;
    #padding: 20px;
}

#other_ex {
    width: 90vh;
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid gray;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
  }
  
  #other_ex_2 {
    padding: 20px;
  }
  
.Article:hover {
  cursor: pointer;
}

.Article .headline {
  #border: 1px solid;
}

.Article .headline a {
  color: black;
  text-decoration: none;
}

.Article .headline h3:hover {
  color: pink;
}

.Article .headline h4 {
  color: gray;
}

.Article h3 {
  margin: none;
}

.Article p {
  font-size: 11px;
}

.Article img {
  border: 1px solid lightgray;
}

.Article {
  display: flex;
  flex-direction: column;
}
