
@import url('https://fonts.googleapis.com/css?family=Buenard:400,700&display=swap');

* {
  font-family: 'Buenard', serif;
  #background-color: #fff0f2;
}

html {
  scroll-behavior: smooth;
}


.App {
  #border: 1px solid lightgray;
  margin-left: auto;
  margin-right: auto;
}


button {
  margin: 0;
  background: none;
  border: none;
}

#header {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

a:visited {
  color: blue;
}

#go_to_top {
  position: fixed;
  background: lightblue;
  right: 20px;
  bottom: 20px;
  padding: 5px;
  display: inline-block;
  border: 1px solid;
}
