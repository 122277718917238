.Article:hover {
  cursor: pointer;
}

.Article .headline {
  #border: 1px solid;
}

.Article .headline a {
  color: black;
  text-decoration: none;
}

.Article .headline h3:hover {
  color: pink;
}

.Article .headline h4 {
  color: gray;
}

.Article h3 {
  margin: none;
}

.Article p {
  font-size: 11px;
}

.Article img {
  border: 1px solid lightgray;
}

.Article {
  display: flex;
  flex-direction: column;
}